import React from "react";
import Icon, { Sort } from "app/components/Icon";
import Tooltip from "app/components/Tooltip";
import styled from "styled-components";
import Flex from "app/components/Flex";
import Text from "app/components/Text";

export const StyledButton = styled.button`
  padding: 0;
  border: 0;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  gap: 4px;
  color: ${({ theme }) => theme.colors.black};
`;

export function MenuButton() {
  return (
    <StyledButton>
      <Tooltip overlay="Sort By">
        <Flex gap="8px">
          <Icon as={Sort} width="20px" height="20px" color="black" />
          <Text fontWeight="bold" fontSize="12px">
            Sort
          </Text>
        </Flex>
      </Tooltip>
    </StyledButton>
  );
}
