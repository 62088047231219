import React from "react";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import { IS_MOBILE } from "constants/index";
import { TooltipProps } from "rc-tooltip/lib/Tooltip";

// For disabled inputs, tooltip stays
// https://github.com/react-component/tooltip/issues/18
// Solution is to wrap children in another element

interface Props extends TooltipProps {
  mouseLeaveDelay?: number;
  placement?: string;
  overlay: string;
}

const CustomTooltip = ({
  mouseLeaveDelay = 0,
  placement = "top",
  children,
  overlay = "",
  ...rest
}: Props) =>
  IS_MOBILE ? (
    <>{children}</>
  ) : (
    <Tooltip
      mouseLeaveDelay={mouseLeaveDelay}
      placement={placement}
      // overlay over Modal and other high z-index elements
      overlayStyle={{ zIndex: 99999999999 }}
      overlay={overlay}
      {...rest}
    >
      {children}
    </Tooltip>
  );

export default CustomTooltip;
